<template>
  <div id="myapp">
    
    <Header active_tab="Home" />
    <div class="mycontainer">
      <div class="row">
        <!-- <div class="col">
            <ImageChangingCard/>
          </div> -->
      </div>
      <div class="row">
        <div class="col">
          <SmallCard
            title="Quick Assessment"
            address="/assessment/1/"
            link="icons8-student"
          />
        </div>
        <div class="col">
          <SmallCard
            title="Standard Assessment"
            address="/assessment/2/"
            link="icons8-student"
          />
        </div>
        <div class="col">
          <SmallCard
            title="Comprehensive Assessment"
            address="/assessment/3/"
            link="icons8-student"
          />
        </div>
        <div class="col">
          <SmallCard
            title="Revision"
            address="/revision/"
            link="bx:bx-book-bookmark"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <SmallCard
            title="Schedule Making"
            address="/schedule"
            link="ant-design:field-time-outlined"
          />
        </div>
        
        <div class="col">
          <SmallCard
            title="Essay and Answer Writing"
            address="/essay/"
            link="uil:clipboard-notes"
          />
        </div>
        <div class="col">
          <SmallCard
            title="Proforma"
            address="/proforma/"
            link="mdi:card-account-details"
          />
        </div>
        <div class="col">
          <SmallCard
            title="Help and Instructions"
            address="/help/main-help"
            link="emojione-monotone:question-mark"
          />
        </div>
      </div>

      <div></div>
      <!-- <router-link to="/">Home</router-link> -->
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";

const SmallCard = defineAsyncComponent(() =>
  import("@/components/SmallCard.vue")
);
const Header = defineAsyncComponent(() => import("@/components/Header"));
const ImageChangingCard = defineAsyncComponent(() =>
  import("@/components/ImageChangingCard")
);
import store from "../store";
export default {
  name: "Home",

  components: {
    Header,
    SmallCard,
    ImageChangingCard
  },
  async mounted() {
    store.state.loading = false;
  }
};
</script>
<style scoped>
#myapp {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  /* background: var(--light-gray); */
  /* background-image: url('~@/assets/background.jpg'); */
  /* background: linear-gradient(-45deg, #f7673b, #e73c7e, #acc9d3, #ccf5eb); */
  background-image: linear-gradient(white 5%,red);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}



</style>